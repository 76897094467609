













































import type { Picture } from '@/inc/types'
import {
  defineComponent,
  PropType,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { gsap, TimelineLite } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger.js'
import { debounce } from 'throttle-debounce'

export interface AWGallery {
  title?: string
  htmltext?: string
  gallery: Picture[]
}

export default defineComponent({
  name: 'AWGallery',
  components: {},
  props: {
    content: {
      type: Object as PropType<AWGallery>,
      required: true,
    },
  },

  setup() {
    const rootElRef = ref<HTMLElement | null>(null)
    const sliderElRef = ref<HTMLElement | null>(null)
    const listsElRef = ref<HTMLElement[]>([])
    const imgElsRef = ref<HTMLImageElement[]>([])
    const isStaging = ['staging', 'development'].includes(
      process.env.VUE_APP_RELEASE as string
    )
    let tl: TimelineLite
    let totalWidth = 0
    let sliderHeight = 0
    let maxLength = 0

    const isMobileRef = ref(false)
    const breakpoint = 1024

    // Create promises to wait for images load
    const loadImages = () =>
      new Promise<void>(resolve => {
        const imgElements = rootElRef.value?.querySelectorAll('img')
        const imgPromises: Promise<void>[] = []

        if (imgElements && imgElements.length > 0) {
          imgElements?.forEach(img => {
            imgPromises.push(
              new Promise(resolve => {
                if (img.complete) {
                  // if img is cached, resolve immediately
                  resolve()
                } else {
                  // or wait for it to load
                  img.onload = () => {
                    resolve()
                  }
                }
              })
            )
          })

          Promise.all(imgPromises).then(() => resolve())
        } else {
          resolve()
        }
      })

    // Create timeline: animation is based on list elements
    const createTl = () => {
      tl = gsap.timeline({
        // Add scrolltrigger to pause animation when not in viewport
        scrollTrigger: {
          id: 'aw-gallery',
          trigger: sliderElRef.value as Element,
          toggleActions: 'play pause resume pause',
        },
      })
      totalWidth = 0

      // Add each list width to the total width
      listsElRef.value.forEach((listEl: HTMLElement) => {
        tl.set(listEl, {
          x: totalWidth,
        })

        totalWidth += listEl.clientWidth

        maxLength =
          listEl.clientWidth > maxLength ? listEl.clientWidth : maxLength
      })

      // Add lists to timeline
      tl.to(
        listsElRef.value,
        {
          duration: isMobileRef.value ? 70 : 120,
          ease: 'none',
          x: `-=${totalWidth}`,
          modifiers: {
            x: gsap.utils.unitize(x =>
              x < -maxLength ? (x % totalWidth) + totalWidth : x
            ),
          },
          repeat: -1,
        },
        0
      )
    }

    // On resize, check if we need to refresh TL because slider height has changed
    const onResize = debounce(200, (x: number) => {
      if (!sliderElRef.value) {
        return
      }

      isMobileRef.value = x < breakpoint

      const newSliderHeight = sliderElRef.value.clientHeight

      if (sliderHeight !== newSliderHeight) {
        sliderHeight = newSliderHeight

        ScrollTrigger.getById('aw-gallery')?.kill()
        tl.kill()
        createTl()
      }
    })

    onMounted(async () => {
      gsap.registerPlugin(ScrollTrigger)
      isMobileRef.value = window.innerWidth < breakpoint

      try {
        // Wait for all imgs to be loaded before creating animation
        await loadImages()
        createTl()
        rootElRef.value?.classList.add('is-loaded')

        // Save slider height to control later if we need to reset TL or not
        if (sliderElRef.value) {
          sliderHeight = sliderElRef.value.clientHeight
        }
      } catch (e) {
        console.error('Error loading images', e)
      }
    })

    onUnmounted(() => {
      ScrollTrigger.getById('aw-gallery')?.kill()
      tl.kill()
    })

    return {
      rootElRef,
      sliderElRef,
      listsElRef,
      imgElsRef,
      isStaging,
      onResize,
    }
  },
})
